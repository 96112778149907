import { render, staticRenderFns } from "./Vibrational.vue?vue&type=template&id=f56f4748&scoped=true&"
import script from "./Vibrational.vue?vue&type=script&lang=js&"
export * from "./Vibrational.vue?vue&type=script&lang=js&"
import style0 from "./Vibrational.vue?vue&type=style&index=0&id=f56f4748&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f56f4748",
  null
  
)

/* custom blocks */
import block0 from "./Vibrational.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports