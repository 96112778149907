<i18n>
{
  "en": {
    "structureTitle": "Vibration monitoring tool",
    "structureSubTitle": "Select a Vibrometer sensor to analyze its data."

  },
  "it": {
    "structureTitle": "Tool per il monitoraggio vibrazionale",
    "structureSubTitle": "Seleziona un sensore vibrometro di cui analizzare i dati."
  }
}
</i18n>
<template>
  <div>
    <PageTemplate :hideTips="true" @needToReloadEvents="onNeedToReloadEvents">
      <template v-slot:nav>
        <DateRangeSelect
          :initDateRange="getDateRange"
          @date-selected="setDateRange"
          @structure-selected="selectStructure"
          :selectedStructure="getStructureDetails"
          :availableStructures="getStructures"
          :minDate="firstValidDate"
        />
      </template>
      <template v-slot:content>
        <!-- Loading -->
        <transition name="load">
          <LoadingLogo v-if="false && !APILoaded" />
        </transition>
        <!-- Modulo Struttura -->
        <Generic
          id="generic"
          :title="$t('structureTitle')"
          :subTitle="$t('structureSubTitle')"
          v-if="getStructureDetails && getStructureDetails.type === 'generic'"
          :selectedStructure="getStructureDetails"
          :multiSensorSelect="false"
          :typesFilter="['vibrometer']"
          @sensor-selected="setSensorsPool"
          @refresh="fetchStructureData"
        />
        <BridgeInfo
          id="bridge-inf"
          :typesFilter="['vibrometer']"
          :multiSensorSelect="false"
          :structureDetails="getStructureDetails"
          v-if="getStructureDetails && getStructureDetails.type != 'generic'"
          :selectedSensors="getSensorPool || []"
          @sensor-selected="setSensorsPool"
        />
        <PPVNormativeCharts id="ppv-chart" v-for="sensor in getSensorPool" :key="sensor && sensor.eui" :sensorObj="sensor" :PPVs="getPPVs" :loading="!APILoaded" :minimalView="false"/>
      </template>
    </PageTemplate>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import PPVNormativeCharts from "../../components/modules_V2/PPVNormativeCharts.vue";
import PageTemplate from "../PageTemplate.vue";
import DateRangeSelect from "../../components/ui/DRSelect.vue";
import BridgeInfo from "../../components/modules/dashboard/BridgeInfo.vue";
import Generic from "../../components/structures/Generic.vue";
import LoadingLogo from "../../components/ui/LoadingLogo.vue";

export default {
  name: "PPVTool",
  components: {
    PageTemplate,
    PPVNormativeCharts,
    DateRangeSelect,
    BridgeInfo,
    Generic,
    LoadingLogo,
  },
  data() {
    return {
      loaded: true,
      firstLoading: true,
      firstValidDate: null, // Prima data valida per la visualizzazione
      fullAxes: ["x", "y", "z"],
      baseTrace: {
        connectgaps: true,
        /* Trace di base a cui vengono aggiunti attributi in base al grafico */
        line: {
          color: "rgb(17, 205, 239)",
          width: 4,
        },
        marker: {
          color: "rgb(255, 255, 255)",
          size: 4,
        },
        name: "Peaks Line",
        type: "scatter",
        xaxis: "x",
        yaxis: "y",
        opacity: 0.9,
        textfont: {},
      },
    };
  },
  methods: {
    ...mapActions({
      fetchStructures: "structure/fetchStructures",
      selectStructure: "structure/selectStructure",
      fetchStructureData: "structure/fetchStructureData",
      setSensorsPool: "ppv/setSensorsPool",
      logout: "auth/logout",
      fetchUserSettings: "user/fetchUserSettings",
      setDateRange: "ppv/setDateRange",
      fetchPPV_API: "ppv/fetchPPV_API",
    }),
    getAxisIndex(axis) {
      return this.fullAxes.indexOf(axis);
    },
    async onNeedToReloadEvents(){
      await this.fetchPPV_API();
    }
  },
  computed: {
    ...mapGetters({
      getStructures: "structure/getStructures",
      getSensors: "structure/getSensors",
      getSelectedStructure: "structure/getSelectedStructure",
      getStructureDetails: "structure/getStructureDetails",
      getSensorPool: "ppv/getSensorPool",
      getDateRange: "ppv/getDateRange",
      getPPVs: "ppv/getTotalStats",
      APILoaded: "ppv/getLoadingStatus",
    }),
  },
  watch: {
    getSensorPool: async function(newValue) {
      if (newValue && newValue[0]) {
        // Se è la prima volta che carico, setto lastDate ad oggi altrimenti prendo quella attuale
        let lastDate;
        if (this.firstLoading) {
          lastDate = new Date();
        } else {
          lastDate = this.getDateRange[1];
        }
        // Adeguo la prima data del fetch coerentemente con l'ultima config del sensore (prendo il min. tra lei e 2 giorni fa)
        let firstDate = new Date();
        firstDate.setDate(firstDate.getDate() - 15);
        const lastChange = newValue[0].userConfig.threshold.lastChange;
        const d = new Date(lastChange ? Math.max(firstDate, new Date(lastChange)) : firstDate);
        this.firstValidDate = d;
        //const currentLastDate = this.getDateRange[1];
        await this.setDateRange([d, lastDate]);
        //await this.fetchPPV_API();
        this.firstLoading = false;
      } else {
        // Mi trovo qui se non ci sono sensori selezionati -> seleziono un date ragne dummy
        let lastDate = new Date();
        let firstDate = new Date();
        firstDate.setDate(firstDate.getDate() - 15);
        await this.setDateRange([firstDate, lastDate]);
      }
    },
    getDateRange: async function() {
      if (this.getSensorPool && this.getSensorPool[0]) {
        console.log("[VIBRATIONAL] New Date Range");
        await this.fetchPPV_API();
      }
    },
  },
};
</script>
<style scoped>
#status-row {
  width: 92%;
  margin: auto;
  display: grid;
  grid-template-columns: 6.5fr 3.5fr;
  column-gap: 20px;
  grid-template-rows: min-content;
}
#generic {
  margin-top: 20px;
}
#bridge-ow {
  max-width: 100vw;
}
#ppv-chart {
  width: 96%;
  margin: auto;
}
</style>
